import ScreenerFilteredResult from './ScreenerFilteredResult';
import * as Icon from 'react-bootstrap-icons';
import SuperInvestorStocks from './SuperInvestorStocks';

export default function Screener() {

    return (
        <>
            <h2 className="text-center">Discover Promising Stocks with Strong Fundamentals</h2>
            <p>&nbsp;</p>
            <div align="left" style={{width: '50%', display: 'inline-block', paddingLeft: '3%'}}>
                <h3 className="text-muted"><Icon.BarChart /> &nbsp; Unusual Volume:</h3>
            </div>
            <ScreenerFilteredResult filter="STOCKS_WITH_UNUSUAL_VOLUME" />
            <p>&nbsp;</p>
            <div align="left" style={{width: '50%', display: 'inline-block', paddingLeft: '3%'}}>
                <h3 className="text-muted"><Icon.GraphDown /> &nbsp; 52 week low large Cap stocks:</h3>
            </div>
            <ScreenerFilteredResult filter="STOCKS_52_WEEK_LOW" />
            <p>&nbsp;</p>
            <div align="left" style={{width: '50%', display: 'inline-block', paddingLeft: '3%'}}>
                <h3 className="text-muted"><Icon.Check2Circle /> &nbsp; Opportunities:</h3>
            </div>
            <ScreenerFilteredResult filter="STOCKS_OPPORTUNITY" />
            <p>&nbsp;</p>
            <div align="left" style={{width: '50%', display: 'inline-block', paddingLeft: '3%'}}>
                <h3 className="text-muted"><Icon.ExclamationOctagon /> &nbsp; Overbought and Risky:</h3>
            </div>
            <ScreenerFilteredResult filter="STOCKS_OVERBOUGHT_AND_RISKY" />
            <p>&nbsp;</p>
            <div align="left" style={{width: '50%', display: 'inline-block', paddingLeft: '3%'}}>
                <h3 className="text-muted"><Icon.Megaphone /> &nbsp; Less than year IPO:</h3>
            </div>
            <ScreenerFilteredResult filter="STOCKS_NEW_AFTER_IPO" />
            <p>&nbsp;</p>
            <SuperInvestorStocks />
        </>
    );
}