import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import TickerLink from './helpers/TickerLink';
import Button from 'react-bootstrap/Button';
import { TwitterShareButton } from 'react-share';
import * as Icon from 'react-bootstrap-icons';

//https://medium.com/codex/implementation-of-server-sent-events-and-eventsource-live-progress-indicator-using-react-and-723596f35225
//https://dev.to/1stmuse/client-side-pagination-with-react-300j
export default function News({ticker}) {
    const [news, setNews] = useState([]);
    news.sort((news1, news2) => (news2.published_utc > news1.published_utc) ? 1 : -1);

    const [pageNumber, setPageNumber]= useState(1)
    const [newsNumber]= useState(20)
    const currentPageNumber = (pageNumber * newsNumber) - newsNumber
    const paginatedNews = news.filter(n => ticker === ""  || n.tickers.includes(ticker)).splice(currentPageNumber, newsNumber)

    const handlePrev=() => {
        if(pageNumber === 1) {
            return;
        }

        setPageNumber(pageNumber - 1);
    }
    const handleNext=() => {
        setPageNumber(pageNumber + 1)
    }

    useEffect(() => {
        const eventSource = new EventSource(process.env.REACT_APP_NEWS_URL)
        eventSource.onmessage =  event => {
              var message = JSON.parse(event.data);
              setNews(news => [...news, message]);
        }
    }, []);

  return (
    <>
    <h3 className="text-muted"><Icon.Activity />  US stock market news</h3>
    <Table striped bordered hover variant="dark" responsive="sm">
      <thead>
        <tr>
          <th></th>
          <th>Published at</th>
          <th>Tickers</th>
          <th>Title</th>
          <th>Share</th>
        </tr>
      </thead>
      <tbody>
        {paginatedNews.map(({ id, title, article_url, description, published_utc, tickers, keywords, publisher}, i) => (
            <tr key={id+"_"+i}>
                <td>
                    <img src={publisher.favicon_url} width="22" height="22" alt={description} />
                </td>
                <td>
                    <small className="text-muted">{new Date(published_utc).toLocaleString() + ""}</small>
                </td>
                <td>
                    <small className="text-muted" title={keywords}>
                        {tickers.map((ticker, j) => (
                            <TickerLink key={id+"_"+ticker} ticker={ticker + " "}/>
                        ))}
                    </small>
                </td>
                <td>
                    <a href={article_url} target="_blank" rel="noreferrer"
                        title={description}
                        style={{ textDecoration: 'none', fontSize: '13px' }}>{title}</a>
                </td>
                <td className="text-center">
                    <TwitterShareButton url={'https://www.sigmatrader.net/news'} title={title} hashtags={["sigmatrader", "trading"].concat(tickers)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                        </svg>
                    </TwitterShareButton>
                </td>
            </tr>
        ))}
        <tr>
            <td colSpan="5" className="text-center">
                <Button onClick={handlePrev} variant="outline-secondary">&lt;&lt; Prev</Button>
                &nbsp;Page <b>{pageNumber}</b>&nbsp;
                <Button onClick={handleNext} variant="outline-secondary">Next &gt;&gt;</Button>
            </td>
        </tr>
      </tbody>
   </Table>
   </>
  );
}