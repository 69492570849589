export default function About() {
  return (
    <>
    <h1>About SigmaTrader</h1>
    <p>Welcome to SigmaTrader, your go-to destination for comprehensive information and insights into the dynamic world of trading, specifically focused on the US stock market and NASDAQ. We strive to provide a platform where traders, both novice and experienced, can access vital data, analysis, and tools to make informed investment decisions.</p>

    <h3>Our Mission</h3>
    At SigmaTrader, our mission is to empower traders with the knowledge and tools needed to navigate the complexities of the stock market. We believe in fostering a community where members can share experiences, learn from each other, and enhance their trading skills.

    <p></p>
    <h3>What We Offer</h3>
    <h4>1. Daily Gapped Stocks</h4>
    Stay updated with the latest gapped stocks in the US market. Gaps can provide valuable insights into potential market movements and trading opportunities.
    <p>&nbsp;</p>
    <h4>2. Stock News</h4>
    Access timely and relevant news updates about major stocks like Apple, Microsoft, and more. Our curated news section ensures you're always in the loop with what's happening in the stock market.
    <p>&nbsp;</p>
    <h4>3. Stock Data</h4>
    Get access to essential stock data, including Average True Range (ATR), volume, short float, and more. Analyze these metrics to make informed trading decisions.
    <p>&nbsp;</p>
    <h4>4. Stock Charts with TradingView Integration</h4>
    Visualize stock trends and patterns using our integrated TradingView charts. Analyze stock behavior and create your strategies with ease.
    <p>&nbsp;</p>
    <h4>5. Market Clock</h4>
    Track market hours and stay informed about the trading sessions. The market clock helps you plan your trades efficiently and take advantage of different market phases.
    <p>&nbsp;</p>
    <h4>6. Daily Quotes</h4>
    Get inspired and motivated with our daily quotes related to trading and success. Stay focused on your trading goals and aspirations.
    <p>&nbsp;</p>
    <h4>7. Economic Calendar</h4>
    Stay informed about upcoming economic events and their potential impact on the market. Our economic calendar helps you plan your trades around major financial announcements.
    </>
  );
}
