import React, { useState, useEffect } from "react";

export default function DailyQuote() {
    const [quote, setQuote] = useState({});

    async function fetchDailyQuote(){
          const response = await fetch(`${process.env.REACT_APP_DAILY_QUOTE_URL}`);
          setQuote(await response.json())
    }

    useEffect(() => {
        fetchDailyQuote();
    }, []);

  return (
      <>
      <figure>
        <blockquote className="blockquote">
          <p>{quote.body}</p>
        </blockquote>
        <figcaption className="blockquote-footer">
          Daily Trading Quote
        </figcaption>
      </figure>
      </>
  );
}