import Spinner from 'react-bootstrap/Spinner';

export default function Loading({visibility}) {
    return (
        <div className={visibility}>
            <Spinner animation="grow" size="sm" />&nbsp;
            <Spinner animation="grow" size="sm" />&nbsp;
            <Spinner animation="grow" size="sm" />
        </div>
    );
}