import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Helmet from "react-helmet";

export default function MarketHolidays() {
    const [marketHolidays, setMarketHolidays] = useState([])

    async function fetchMarketHolidays(){
        const response = await fetch(process.env.REACT_APP_MARKET_HOLIDAYS_URL);
        setMarketHolidays(await response.json());
    }
    useEffect(() => {
        fetchMarketHolidays();
    }, []);

    return (
        <>
             <Helmet>
                  <title>Sigma Trader - Upcoming US stock Market Holidays</title>
             </Helmet>
            <div align="left" style={{padding: '10px'}}>🇺🇸 <small><b>Upcoming Market Holidays</b></small></div>
            <Table striped bordered hover variant="dark" responsive="sm">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Exchange</th>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {marketHolidays.map(({ date, exchange, name, status}, i) => (
                    <tr key={date+"_"+exchange+"_"+i}>
                        <td>
                           {date}
                        </td>
                        <td>
                            {exchange}
                        </td>
                        <td>
                            {name}
                        </td>
                        <td className="text-center">
                            {status}
                        </td>
                    </tr>
                ))}
              </tbody>
           </Table>
        </>
    );
}