import React, { useEffect, useRef } from 'react';

let tvScriptLoadingPromise;

export default function TikTok() {
  const onLoadScriptRef = useRef();
  useEffect(
    () => {

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tiktok-widget-loading-script';
          script.src = 'https://www.tiktok.com/embed.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

    },
    []
  );


  return (
    <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@sigmatrader.net" data-unique-id="sigmatrader.net" data-embed-type="creator" style={{maxWidth: '100%', minWidth: '100%'}}>
        <section />
    </blockquote>
  );
}
