import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import TickerLink from './helpers/TickerLink';
import { useSearchParams } from 'react-router-dom'

export default function Gapper({getStockData, setTicker, url}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [gappersMap, setGappers] = useState(new Map());

    useEffect(() => {
        const eventSource = new EventSource(url)
        eventSource.onmessage =  event => {
              let message = JSON.parse(event.data);
              setGappers(map => new Map(map.set(message.ticker, message)));
        }
    }, []);

    const handleTickerDetails = async (e) => {
//        e.preventDefault();

        var ticker = e.target.innerText.toUpperCase();
        setSearchParams({search: ticker});
    }

    if(gappersMap.size === 0){
        return (<p> - </p>);
    }

    return (
        <>
        <Table striped bordered hover variant="dark" responsive="sm">
          <thead>
            <tr>
              <th width="200">Ticker</th>
              <th>Change %</th>
              <th>Change $</th>
              <th>Last sale price</th>
              <th width="200">Details</th>
            </tr>
          </thead>
          <tbody>
            {[...gappersMap.values()].map(({ ticker, exchange, primaryData}, id) => (
                <tr key={ticker}>
                    <td>
                        <small className="text-muted">
                            <TickerLink ticker={ticker}/>
                        </small>
                    </td>
                    <td>
                        {primaryData.percentageChange}
                    </td>
                    <td>
                        {primaryData.netChange}
                    </td>
                    <td>
                        {primaryData.lastSalePrice}
                    </td>
                    <td>
                        <div className="d-grid gap-2">
                            <Button variant="outline-light" size="sm" onClick={handleTickerDetails}>{ticker}</Button>
                        </div>
                    </td>
                </tr>
            ))}
          </tbody>
        </Table>
        </>
    );
}