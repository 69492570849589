
export default function EconomicData() {

  return (
    <>
       <h5>CPI (less Food and Energy)</h5>
        <small className="text-muted">The Sticky Price Consumer Price Index (CPI) is calculated from a subset of
        goods and services included in the CPI that change price relatively infrequently.
        Because these goods and services change price relatively infrequently, they are thought to incorporate
        expectations about future inflation to a greater degree than prices that change on a more frequent basis.
        One possible explanation for sticky prices could be the costs firms incur when changing price.
        </small>
        <p>Frequency:  Monthly</p>
        <div className='embed-container'>
            <iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=1k6gW&width=100%&height=475"
                scrolling="no" frameBorder="0"
                style={{overflow:"hidden", width:"100%", height:"525px"}}
                allowtransparency="true" loading="lazy">
            </iframe>
        </div>

        <p>&nbsp;</p>
        <h5>GDP</h5>
        <small className="text-muted">Gross domestic product (GDP), the featured measure of U.S. output, is the market
        value of the goods and services produced by labor and property located in the United States.
        </small>
        <p>Frequency:  Quarterly</p>
        <div className='embed-container'>
            <iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=1lk6a&width=100%&height=475"
                scrolling="no" frameBorder="0"
                style={{overflow:"hidden", width:"100%", height:"525px"}}
                allowtransparency="true" loading="lazy">
            </iframe>
        </div>

        <p>&nbsp;</p>
        <h5>Unemployment Rate</h5>
        <small className="text-muted">The unemployment rate represents the number of unemployed as a percentage of the labor force.</small>
        <p>Frequency:  Monthly</p>
        <div className='embed-container'>
            <iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=1jGaT&width=100%&height=475"
                scrolling="no" frameBorder="0"
                style={{overflow:"hidden", width:"100%", height:"525px"}}
                allowtransparency="true" loading="lazy">
            </iframe>
        </div>

        <p>&nbsp;</p>
        <h5>Federal Funds Effective Rate</h5>
        <small className="text-muted">The federal funds rate is the central interest rate in the U.S. financial market.
        It influences other interest rates such as the prime rate, which is the rate banks charge their customers
        with higher credit ratings. Additionally, the federal funds rate indirectly influences longer- term interest
        rates such as mortgages, loans, and savings, all of which are very important to consumer wealth
        and confidence.
        </small>
        <p>Frequency:  Daily</p>
        <div className='embed-container'>
            <iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=1n07T&width=100%&height=475"
                scrolling="no" frameBorder="0"
                style={{overflow:"hidden", width:"100%", height:"525px"}}
                allowtransparency="true" loading="lazy">
            </iframe>
        </div>

        <p>&nbsp;</p>
        <h5>Real-time Sahm Rule Recession Indicator</h5>
        <small className="text-muted">Sahm Recession Indicator signals the start of a recession when the
        three-month moving average of the national unemployment rate (U3) rises by 0.50 percentage points or more
        relative to the minimum of the three-month averages from the previous 12 months.
        </small>
        <p>Frequency:  Monthly</p>
        <div className='embed-container'>
            <iframe src="https://fred.stlouisfed.org/graph/graph-landing.php?g=1n0NH&width=100%&height=475"
                scrolling="no" frameBorder="0"
                style={{overflow:"hidden", width:"100%", height:"525px"}}
                allowtransparency="true" loading="lazy">
            </iframe>
        </div>
    </>
  );
}
