import React, { useEffect, useRef } from 'react';

export default function TickerTape() {
  const container = useRef();

  useEffect(
    () => {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "symbols": [
            {
              "description": "S&P 500",
              "proName": "CAPITALCOM:US500"
            },
            {
              "description": "NASDAQ",
              "proName": "CAPITALCOM:US100"
            },
            {
              "description": "DOW",
              "proName": "CAPITALCOM:US30"
            },
           {
             "description": "BITCOIN",
             "proName": "COINBASE:BTCUSD"
           },
           {
             "description": "GOLD",
             "proName": "CAPITALCOM:GOLD"
           },
           {
             "description": "SILVER",
             "proName": "CAPITALCOM:SILVER"
           },
           {
             "description": "OIL",
             "proName": "TVC:USOIL"
           }
          ],
          "showSymbolLogo": true,
          "colorTheme": "dark",
          "isTransparent": false,
          "displayMode": "adaptive",
          "locale": "en"
        }`;
      container.current.appendChild(script);
    },
    []
  );

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}