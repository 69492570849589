import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import * as Icon from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { TwitterShareButton } from 'react-share';

export default function Earnings() {
    const [earnings, setEarnings] = useState([]);
    const [dateWindow, setDateWindow] = useState("TODAY_TOMORROW");

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    async function fetchEarnings(){
          const response = await fetch(`${process.env.REACT_APP_EARNINGS_URL.replace("{dateWindow}", dateWindow)}`);
          setEarnings(await response.json())
    }

    useEffect(() => {
        fetchEarnings();
    }, [dateWindow]);

    const handleEarningDateWindowChange = (val) => {
        setDateWindow(val);
    }

    function getIconForTime(time){
        if(time === 'BMO'){
            return <Icon.SunFill />
        }
        if(time === 'AMC'){
            return <Icon.MoonFill />
        }

        return <b></b>;
    }

    function extractCoupleBigStocksForShare(){
        var tickers = "";
        earnings.forEach(function(earning){
            if(tickers.length > 30) {
                return;
            }
            tickers += " $"+earning.ticker;
        })

        return tickers;
    }

  return (
      <>
        <div align="left" style={{width: '50%', display: 'inline-block', paddingLeft: '35px'}}>
            <h3 className="text-muted"><Icon.Calendar2Check />  Biggest US Stocks Before Earnings:</h3>
        </div>
        <div align="right" style={{width: '50%', display: 'inline-block', paddingRight: '35px'}}>
            Share on &nbsp;
            <TwitterShareButton url={'https://www.sigmatrader.net'} title={"Biggest Stocks Before Earnings ".concat(extractCoupleBigStocksForShare())} hashtags={["sigmatrader", "trading", "investing"]}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                </svg>
            </TwitterShareButton>
        </div>
        <div className="text-center">
            <ToggleButtonGroup type="radio" name="options" defaultValue="TODAY_TOMORROW" onChange={handleEarningDateWindowChange} style={{width: '55%'}}>
                <ToggleButton id="tbg-radio-1" value="YESTERDAY" variant="outline-secondary">
                  Yesterday
                </ToggleButton>
                <ToggleButton id="tbg-radio-2" value="TODAY_TOMORROW" variant="outline-secondary">
                  Today and Tomorrow
                </ToggleButton>
                <ToggleButton id="tbg-radio-3" value="THIS_WEEK" variant="outline-secondary">
                  This week
                </ToggleButton>
                <ToggleButton id="tbg-radio-4" value="NEXT_WEEK" variant="outline-secondary">
                  Next week
                </ToggleButton>
              </ToggleButtonGroup>
        </div>
        <Container fluid="md">
            <Row>
                {earnings.map(({ date, ticker, name, logo, time}, i) => (
                    <Col className="text-center" key={ticker+"_"+i} style={{padding: "20px"}}>
                        <Image src={logo} rounded style={{width: '192px'}} title={ticker +" - "+ name} alt={ticker +" - "+ name} /><br/>
                        <Button variant="outline-light" size="sm" style={{width: '88%', marginTop: '7px'}} onClick={() => openInNewTab('https://sigmatrader.net?search='+ticker)}>{ticker}</Button><br/>
                        <small className="text-muted">{name}</small><br/>
                        <small><b>{date}</b></small> {getIconForTime(time)}
                    </Col>
                ))}
            </Row>
        </Container>
      </>
  )
}