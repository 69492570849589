import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import bullImage from './images/bull.png';
import bearImage from './images/bear.png';

const BearBullSentiment = () => {
  const [items, setItems] = useState([
    { id: 'item1', weight: 3, title: "🛢️ Oil" },
    { id: 'item2', weight: 3, title: "😨 VIX" },
    { id: 'item3', weight: 3, title: "💪 RSI" },
    { id: 'item4', weight: 3, title: "🫰 CPI"},
    { id: 'item5', weight: 3, title: "💸 Inflation"},
    { id: 'item6', weight: 3, title: "🌎 Geopolitical"},
    { id: 'item7', weight: 3, title: "📊 GDP"},
    { id: 'item8', weight: 3, title: "📐 SMA50"},
  ]);

  const [seesawAngle, setSeesawAngle] = useState(0);

  const handleDragEnd = (result) => {
      if (!result.destination) return;

      const { source, destination } = result;

      const draggedItem = items.find(item => {
        return item.id === result.draggableId.split('-')[0];
      });

      const destinationPosition = destination.droppableId === 'left' ? 'left' : 'right';
      draggedItem.position = destinationPosition;

      const leftWeight = items.filter((item) => item.position === 'left').reduce((acc, item) => acc + item.weight, 0);
      const rightWeight = items.filter((item) => item.position === 'right').reduce((acc, item) => acc + item.weight, 0);
      const angle = (rightWeight - leftWeight) * 0.5;
      setSeesawAngle(angle);
  };


  const handleItemClick = (itemId) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, position: null };
      }
      return item;
    });

    const leftWeight = updatedItems.filter((item) => item.position === 'left').reduce((acc, item) => acc + item.weight, 0);
    const rightWeight = updatedItems.filter((item) => item.position === 'right').reduce((acc, item) => acc + item.weight, 0);

    const angle = (rightWeight - leftWeight) * 0.5;
    setSeesawAngle(angle);
    setItems(updatedItems);
  };

  return (
    <div>
      <h2>Bear Bull Sentiment - SeeSaw</h2>
      <small className="text-muted">Drag and Drop to choose side</small>

      <div
        style={{
          height: '200px',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          style={{
            width: '400px',
            height: '15px',
            backgroundColor: '#fff',
            transformOrigin: 'center center',
            transition: 'transform 0.3s ease-in-out',
            transform: `rotate(${seesawAngle}deg)`,
          }}
        >
        <div
            style={{
              position: 'absolute',
              bottom: '-50px',
              left: 'calc(45% - 5px)',
              width: 0,
              height: 0,
              borderLeft: '20px solid transparent',
              borderRight: '20px solid transparent',
              borderTop: '50px solid white',
              transform: 'rotate(180deg)',
            }}
          />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="left" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  width: '200px',
                  height: '200px',
                  backgroundImage: `url(${bearImage})`,
                   backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  border: '1px solid #ccc',
                }}
              >
                {items.filter((item) => item.position === 'left').map((item, index) => (
                  <Draggable key={item.id} draggableId={`${item.id}-left`} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: 'red',
                          padding: '10px',
                          margin: '10px',
                          cursor: 'pointer',
                          borderRadius: '5px'
                        }}
                        onClick={() => handleItemClick(item.id)}
                      >
                        {item.title}
                      </div>
                    )}
                  </Draggable>
                ))}
                <div style={{ textAlign: 'center' }}>
                    Bear
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>



          <Droppable droppableId="middle" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  width: '200px',
                  height: '450px',
                  border: '1px solid #fff',
                  marginLeft: '20px',
                  marginRight: '20px',
                }}
              >
                {items.filter((item) => !item.position).map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: '#404040',
                          padding: '10px',
                          margin: '10px',
                          cursor: 'pointer',
                          borderRadius: '5px'
                        }}
                        onClick={() => handleItemClick(item.id)}
                      >
                        {item.title}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>



          <Droppable droppableId="right" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  width: '200px',
                  height: '200px',
                  backgroundImage: `url(${bullImage})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  border: '1px solid #ccc',
                }}
              >
                {items.filter((item) => item.position === 'right').map((item, index) => (
                  <Draggable key={item.id} draggableId={`${item.id}-right`} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: 'darkgreen',
                          padding: '10px',
                          margin: '10px',
                          cursor: 'pointer',
                          borderRadius: '5px'
                        }}
                        onClick={() => handleItemClick(item.id)}
                      >
                        {item.title}
                      </div>
                    )}
                  </Draggable>
                ))}
                <div style={{ textAlign: 'center' }}>
                    Bull
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default BearBullSentiment;