import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import TikTok from "./TikTok"
import MyModal from "./helpers/MyModal"

export default function EconomicCalendar() {
    const [economicEvents, setEconomicEvents] = useState({})
    const [modalShow, setModalShow] = useState(false);

    async function fetchEconomicEvents(){
        const response = await fetch(process.env.REACT_APP_MARKET_ECONOMIC_EVENTS_URL);
        setEconomicEvents(await response.json());
    }
    useEffect(() => {
        fetchEconomicEvents();
    }, []);

    useEffect(() => {
        if(economicEvents.events == null){
            return;
        }

        const economicEventTimesSet = new Set();//Unique event time
        for (const economicEvent of economicEvents.events) {
            economicEventTimesSet.add(economicEvent.time)
        }

        for (const economicEventTime of economicEventTimesSet) {
          setIntervalUntilNextEvent(parseTimeStrToUTCDate(economicEventTime).getTime())
        }
    }, [economicEvents]);

    function convertToLocalTime(time) {
        var date = parseTimeStrToUTCDate(time);
        return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    }
    function parseTimeStrToUTCDate(time){
        const [hourNum, minNum] = time.split(':');
        const date = new Date();
        date.setUTCHours(hourNum);
        date.setUTCMinutes(minNum);
        return date;
    }

    function setIntervalUntilNextEvent(nextEventTime){

        var interval = setInterval(function() {
            var distanceBetweenNowAndCountDown = nextEventTime - new Date().getTime();

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distanceBetweenNowAndCountDown / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distanceBetweenNowAndCountDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distanceBetweenNowAndCountDown % (1000 * 60 * 60)) / (1000 * 60));
//            var seconds = Math.floor((distanceBetweenNowAndCountDown % (1000 * 60)) / 1000);

            if (distanceBetweenNowAndCountDown < 0) {
                clearInterval(interval);
            } else if(days === 0 && hours === 0 && minutes < 5) {
                setModalShow(true);
                clearInterval(interval);
            }
        }, 150*1000);
    }

    if(economicEvents.events == null || economicEvents.events.length === 0){
        return (
            <TikTok />
        )
    }

    function isHigh(volatility){
        return volatility.toLowerCase() === "high";
    }

    function formatDate(dateStr){
        return new Intl.DateTimeFormat('en-US', {
                   weekday: 'long',
                   year: 'numeric',
                   month: 'long',
                   day: 'numeric'
                 }).format(new Date(dateStr));
    }

    return (
        <>
            <div align="left" style={{width: '50%', display: 'inline-block'}}>🇺🇸 <small><b>Economic Events</b></small></div>
            <div align="right" style={{width: '50%', display: 'inline-block'}}><small><b>{formatDate(economicEvents.date)}</b></small></div>
            <Table striped bordered hover variant="dark" responsive="sm" size="sm">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Volatility</th>
                  <th>Event</th>
                  <th>Forecast</th>
                  <th>Previous</th>
                </tr>
              </thead>
              <tbody>
                {economicEvents.events != null && economicEvents.events.map(({ time, volatility, title, forecast, previous}, i) => (
                    <tr key={time+"_"+i}>
                        <td>
                           {convertToLocalTime(time)}
                        </td>
                        <td>
                            {isHigh(volatility)? <b>🚨 {volatility}</b>:volatility}
                        </td>
                        <td>
                            {title}
                        </td>
                        <td className="text-center">
                            {forecast}
                        </td>
                        <td className="text-center">
                            {previous}
                        </td>
                    </tr>
                ))}
              </tbody>
           </Table>
           <MyModal
               show={modalShow}
               onHide={() => setModalShow(false)}
               header="💹 Heads Up!"
               title="Important Economic Event is Ahead 📢"
               body="Please check your open positions or prepare to start one based on it!"
             />
        </>
    );
}