import React, { useState, useEffect } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import InputGroup from 'react-bootstrap/InputGroup';

//AutoComplete lib: https://ericgio.github.io/react-bootstrap-typeahead/#basic-example
export default function SearchTicker({showResultsForTicker}) {
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query: string) => {
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_SEARCH_TICKER_URL}&query=${query}`)
          .then((resp) => resp.json())
          .then(data => {
            setOptions(data);
            setIsLoading(false);
          });
    };
    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    useEffect(() => {
        if(searchQuery.length === 0){
            return;
        }

        showResultsForTicker(searchQuery[0].ticker)
    }, [searchQuery]);

    return (
          <InputGroup size="lg" style={{width: '70%', marginLeft: '15%'}}>
                <InputGroup.Text id="search-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                    </svg>
               </InputGroup.Text>
                <AsyncTypeahead
                  style={{border: '0px'}}
                  size="lg"
                  clearButton
                  filterBy={filterBy}
                  id="search-stock"
                  isLoading={isLoading}
                  labelKey="name"
                  minLength={1}
                  onSearch={handleSearch}
                  onChange={setSearchQuery}
                  options={options}
                  placeholder="Search Symbol or Company name, eg. AAPL or Apple"
                  renderMenuItemChildren={(option) => (
                    <span>{option.ticker} - {option.name}</span>
                  )}/>
          </InputGroup>
    );
}