import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import Gapper from './Gapper';
import * as Icon from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom'
import StockData from './StockData';
import NewsByTicker from './NewsByTicker';
import SearchTicker from './SearchTicker';
import Earnings from './Earnings';
import SuperInvestorStocks from './SuperInvestorStocks';

//Icons lib: https://github.com/ismamz/react-bootstrap-icons
export default function Home({stockData, loadingStockDataSpinner, newsByTicker}) {
    const [searchParams, setSearchParams] = useSearchParams({search: ''})
    const [marketStatus, setMarketStatus] = useState({});

    useEffect(() => {
        const eventSource = new EventSource(process.env.REACT_APP_MARKET_STATUS_URL)
        eventSource.onmessage =  event => {
              let data = JSON.parse(event.data)
              setMarketStatus(data);
        }
    }, []);

    function showSearchedTickerData(ticker){
        setSearchParams({search: ticker});
    }

    var homePageBody;
    var marketClock;
    if(marketStatus.marketClock != null){
        marketClock = <Alert variant="dark" className="text-center">
                      <Icon.Bank /> &nbsp; <b>{marketStatus.marketClock.title}</b> - <i>{marketStatus.marketClock.description}</i>
                    </Alert>
    }
    if(searchParams != null && searchParams.get("search") !== ""){
       homePageBody =
       <>
         <StockData stockData={stockData} loadingStockDataSpinner={loadingStockDataSpinner} />
         <p>&nbsp;</p>
         <NewsByTicker newsByTicker={newsByTicker} />
       </>
    } else if(marketStatus.isPreMarket !== true) {
        homePageBody =
        <>
            <p>&nbsp;</p>
            <Earnings />
            <p>&nbsp;</p>
            <SuperInvestorStocks />
        </>
    } else {
        homePageBody =
        <>
            <p className="h6 text-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gem" viewBox="0 0 16 16">
                  <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z"/>
                </svg> Big Names on Pre-market with news
            </p>
            <Gapper url={process.env.REACT_APP_GAPPED_BIG_NAMES_URL} />
            <p className="h6 text-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
                </svg> Gapped up stocks with news behind it
            </p>
            <Gapper url={process.env.REACT_APP_GAPPED_UP_URL} />
            <p className="h6 text-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-down-arrow" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5Z"/>
                </svg> Gapped down stocks with news behind it
            </p>
            <Gapper url={process.env.REACT_APP_GAPPED_DOWN_URL} />
            <p>&nbsp;</p>
            <Earnings />
            <p>&nbsp;</p>
            <SuperInvestorStocks />
        </>
    }

    return (
        <>
        <br/>
        {marketClock}
        <br/>
        <SearchTicker showResultsForTicker={showSearchedTickerData} />
        <br/>
        {homePageBody}
        </>
    );
}