import React, { useEffect, useRef } from 'react';

//https://www.tradingview.com/widget/advanced-chart/
let tvScriptLoadingPromise;

export default function Chart({ticker}) {
  const onLoadScriptRef = useRef();
  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_0c13c') && 'TradingView' in window) {
          new window.TradingView.widget({
            width: "100%",
            height: 610,
            symbol: ticker,
            interval: "D",
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            theme: "dark",
            style: "1",
            locale: "en",
            enable_publishing: false,
            hide_side_toolbar: false,
            hide_legend: true,
            container_id: "tradingview_0c13c"
          });
        }
      }
    },
    [ticker]
  );

  if(ticker === ""){
    return <></>;
  }

  return (
    <div className='tradingview-widget-container'>
      <div id='tradingview_0c13c' />
    </div>
  );
}
