import Table from 'react-bootstrap/Table';
import { TwitterShareButton, TwitterIcon } from 'react-share';

export default function NewsByTicker({newsByTicker}) {
    if (Object.keys(newsByTicker).length === 0){
        return "";
    }
    newsByTicker.sort((news1, news2) => (news2.published_utc > news1.published_utc) ? 1 : -1);

  return (
    <Table striped bordered hover variant="dark" responsive="sm">
      <thead>
        <tr>
          <th width="1%"></th>
          <th width="13%">News Published at</th>
          <th>Title</th>
          <th>Share</th>
        </tr>
      </thead>
      <tbody>
        {newsByTicker.map(({ id, title, article_url, description, published_utc, tickers, keywords, publisher}, i) => (
            <tr key={id+"_"+i}>
                <td>
                    <img src={publisher.favicon_url} width="22" height="22" alt={description}/>
                </td>
                <td>
                    <small className="text-muted">{new Date(published_utc).toLocaleString() + ""}</small>
                </td>
                <td>
                    <a href={article_url} target="_blank" rel="noreferrer"
                    title={description}
                    style={{ textDecoration: 'none' }}>
                        {title}
                    </a>
                </td>
                <td className="text-center">
                    <TwitterShareButton url={'https://www.sigmatrader.net/news'} title={title} hashtags={["sigmatrader", "trading"].concat(tickers)}>
                      <TwitterIcon size={20} />
                    </TwitterShareButton>
                </td>
            </tr>
        ))}
      </tbody>
   </Table>
  );
}