import React, { useState, useEffect } from "react";
import GaugeComponent from 'react-gauge-component'
import Badge from 'react-bootstrap/Badge';
import * as Icon from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


//https://github.com/antoniolago/react-gauge-component
export default function FearGreedIndex() {
    const [data, setData] = useState({});

    useEffect(() => {
        const eventSource = new EventSource(process.env.REACT_APP_FEAR_GREED_INDEX_URL)
        eventSource.onmessage =  event => {
              let message = JSON.parse(event.data)
              message.score = ~~message.score;
              message.rating = message.rating.toUpperCase();
              setData(message);
        }
    }, []);

    const fearGreedIndexHelp = (
      <Popover id="popover-basic">
        <Popover.Header as="h3">Fear and Greed Index</Popover.Header>
        <Popover.Body>
          The <b>Fear and Greed Index</b> is a valuable gauge that attempts to quantify what many think cannot be measured – the emotional state of investors in the stock market.
          <br/><br/>
          The Fear and Greed Index gauges the primary emotions driving investors: fear and greed.
          It is generally accepted that emotional and psychological factors significantly influence investing.
          A high degree of fear can result in stocks being sold off too cheaply, while extreme greed can cause overvaluations.
          <br/><br/>
          Investors can use the Fear and Greed Index as a risk management tool.
          <b>High levels of greed in the market could signal overbought conditions, which may precede a correction or
          a bear market.</b> In contrast, high levels of fear could signal oversold conditions,
          often a precursor to a market rebound.
        </Popover.Body>
      </Popover>
    );

    function formatAndDesignRate(rating){
        var badgeClass = 'warning';
        if(rating != null){
            if(rating.includes("GREED")) {
                badgeClass = 'danger';
            } else if(rating === "FEAR"){
                badgeClass = 'success';
            }
        }

        return <Badge bg={badgeClass}>{rating}</Badge>;
    }

  return (
  <>
    <div align="center" style={{margin: 'auto'}}>
        <GaugeComponent
          value={data.score}
          type="radial"
          style={{
            width: '60%'
          }}
          labels={{
            tickLabels: {
              type: "inner",
              ticks: [
                { value: 0 },
                { value: 25 },
                { value: 50 },
                { value: 75 },
                { value: 100 }
              ],
              defaultTickValueConfig: {
                  formatTextValue: value => value
              }
            },
            valueLabel: { formatTextValue: value =>  value },
          }}
          arc={{
            colorArray: ['#EA4228', '#5BE12C'],
            subArcs: [{length: 25}, {length: 20}, {length: 10}, {length: 20}, {length:25}],
            padding: 0.02,
            width: 0.4
          }}
          pointer={{
            elastic: true,
            animationDelay: 0
          }}
        />
        <small><b>{formatAndDesignRate(data.rating)}</b> sentiment is driving the US market </small>
        <OverlayTrigger trigger="click" placement="right" overlay={fearGreedIndexHelp}>
            <Icon.InfoCircle style={{width: "25px", height: "25px"}} />
        </OverlayTrigger><br/>
        <small className="text-muted">Last updated: {new Date(data.timestamp).toLocaleString() + ""}</small>
    </div>
  </>
  );
}