import React, { useState, useEffect } from "react";
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';

export default function NextMarketHoliday() {
    const [holiday, setHoliday] = useState({});
    const [bgColor, setBgColor] = useState("success");
    const [diffInDays, setDiffInDays] = useState(-1);

    async function fetchNextHoliday(){
          const response = await fetch(`${process.env.REACT_APP_NEXT_MARKET_HOLIDAY_URL}`);
          const holidayRes = await response.json();
          setHoliday(holidayRes)

        var holidayDate = new Date(Date.parse(holidayRes.date));
        var today = new Date();
        var diff = dateDiffInDays(today, holidayDate);

        if(diff < 6 && diff > 3) {
            setBgColor("warning");
        } else if(diff <= 3) {
            setBgColor("danger");
        }

        setDiffInDays(diff);
    }

    useEffect(() => {
        fetchNextHoliday();
    }, []);

    function dateDiffInDays(date1, date2) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
      const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    function opexDayRow(){
        if(isQuadrupleWitchingDay()){
            return <tr>
                   <td>
                       🚨 <Badge bg="danger" text="light">Quadruple Witching</Badge> 🚨 Date. High volatility expected.
                   </td>
               </tr>
        }

        if(isThirdWeekOfMonth()){
            return <tr>
                   <td>
                       🚨 <Badge bg="warning" text="dark">OPEX</Badge> It is Option-Expiration week. High volatility expected.
                   </td>
               </tr>
        }

        return "";
    }

    function isThirdWeekOfMonth(date = new Date()){
        const dayOfWeek = date.getDay();
        const currentDate = date.getDate();

        // If today is not Friday, find the upcoming Friday
        if (dayOfWeek !== 5) {
            date.setDate(currentDate + (5 - dayOfWeek + 7) % 7);
        }

        // Get the day of the month for the upcoming Friday
        const fridayDate = date.getDate();

        // Find the first Friday of the month
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const firstFridayDate = firstDayOfMonth.getDate() + (5 - firstDayOfMonth.getDay() + 7) % 7;

        // Calculate the date of the third Friday
        const thirdFridayDate = firstFridayDate + 14;

        // Check if this Friday is the third Friday of the month
        return fridayDate === thirdFridayDate;
    }

    function isQuadrupleWitchingDay(date = new Date()) {
        const month = date.getMonth();
        const dayOfWeek = date.getDay();

        //Third Friday of March, June, September, and December.
        return [2, 5, 8, 11].includes(month) && dayOfWeek === 5 && isThirdWeekOfMonth();
    }

  return (
      <>
      <Table bordered variant="dark">
        <tbody>
            <tr>
                <td>
                    Upcoming US Holiday <Badge bg={bgColor} text="light">{holiday.name}</Badge> in <b>{diffInDays}</b> days at {holiday.date}
                </td>
            </tr>
            {opexDayRow()}
        </tbody>
      </Table>
      </>
  );
}