import React, { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import * as Icon from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';

export default function Calendar() {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_CALENDAR_WEEKLY_URL}`);
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    if (!data) {
        return <p>Loading...</p>;
    }

    const { earnings, economicEvents } = data;
    const allDates = [
        ...new Set([
            ...earnings.map(item => item.date),
            ...economicEvents.map(item => item.date)
        ])
    ].sort();

    const getDayOfWeek = (dateString) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date(dateString);
        return days[date.getDay()];
    };

    return (
        <div>
            <div align="left">
                <h3 className="text-muted"><Icon.Calendar2Check />  Weekly Earnings and Economic Events:</h3>
            </div>
            <Table bordered variant="dark" responsive="sm">
                <thead>
                    <tr>
                        <th style={{width: '3%'}}>-</th>
                        {allDates.map(date => (
                            <th key={date} className="text-center" style={{width: date !== 'REPORTED' ? '17%' : 'auto'}}>
                                {getDayOfWeek(date)} {date}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="rotated-text">Before Market Open</td>
                        {allDates.map(date => {
                            const earningsBMO = earnings.filter(item => item.date === date && item.time === 'BMO');
                            if (earningsBMO.length === 0) {
                                return <td key={`earnings-bmo-${date}`} />;
                            } else {
                                return (
                                    <td key={`earnings-bmo-${date}`}>
                                        {earningsBMO.map(({ ticker, name, logo }) => (
                                            <div key={ticker}>
                                                 <Image src={logo} rounded style={{width: '50px', margin: '5px'}} title={ticker +" - "+ name} alt={ticker +" - "+ name} />
                                                 <a href={"/?search="+ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                                     {ticker}
                                                 </a>
                                             </div>
                                        ))}
                                    </td>
                                );
                            }
                        })}
                    </tr>
                    <tr>
                        <td className="rotated-text">After Market Close</td>
                        {allDates.map(date => {
                            const earningsAMC = earnings.filter(item => item.date === date && item.time === 'AMC');
                            if (earningsAMC.length === 0) {
                                return <td key={`earnings-amc-${date}`} />;
                            } else {
                                return (
                                    <td key={`earnings-amc-${date}`}>
                                        {earningsAMC.map(({ ticker, name, logo }) => (
                                            <div key={ticker}>
                                                <Image src={logo} rounded style={{width: '50px', margin: '5px'}} title={ticker +" - "+ name} alt={ticker +" - "+ name} />
                                                <a href={"/?search="+ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                                    {ticker}
                                                </a>
                                            </div>
                                        ))}
                                    </td>
                                );
                            }
                        })}
                    </tr>
                    <tr>
                        <td className="rotated-text">Economic Events</td>
                        {allDates.map(date => (
                            <td key={`events-${date}`}>
                                {economicEvents
                                    .filter(item => item.date === date)
                                    .flatMap(({ events }) =>
                                        events.map(event => (
                                            <Badge bg="dark" key={`${event.time}-${event.title}`} style={{ whiteSpace: 'normal', width: '100%', textAlign: 'left' }}>
                                                {event.volatility === 'High' ? (
                                                    <Badge bg="danger" style={{ whiteSpace: 'normal', margin: '5px', padding: '5px', textAlign: 'left' }}>{event.time}</Badge>
                                                ) : (
                                                    <Badge bg="warning" text="dark" style={{ whiteSpace: 'normal', margin: '5px', padding: '5px' }}>{event.time}</Badge>
                                                )}
                                                <small>{event.title}</small>
                                            </Badge>
                                        ))
                                    )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}
