import React, { useState, useEffect } from "react";
import Badge from 'react-bootstrap/Badge';

export default function MyClock() {
    const locale = "en-US";
    function get_NYC_Date(){
        return new Date().toLocaleString(locale, {timeZone: "America/New_York"});
    }

    const [value, setValue] = useState(new Date(get_NYC_Date()));

    const format = val => {
        if (val < 10) {
          val = "0" + val;
        }
        return val;
    };

    useEffect(() => {
        const interval = setInterval(() => setValue(new Date(get_NYC_Date())), 1000);

        return () => {
          clearInterval(interval);
        };
      }, []);

    return (
        <div className="text-center">
            <h3>
            <Badge bg="success" text="light">
            NYC &nbsp;
            <Badge bg="dark" text="light"><b>{`${format(value.getHours())}`}</b></Badge>
            &nbsp;:&nbsp;
            <Badge bg="dark" text="light"><b>{`${format(value.getMinutes())}`}</b></Badge>
            &nbsp;:&nbsp;
            <small><Badge bg="dark" text="light">{`${format(value.getSeconds())}`}</Badge></small>
            &nbsp;
            <small><Badge bg="dark" text="light">{value.toLocaleDateString(locale, { weekday: 'long' })}</Badge></small>
            </Badge>
            </h3>
        </div>
    );
}