import React, { useState, useEffect } from "react";
import * as Icon from 'react-bootstrap-icons';
import { TwitterShareButton } from 'react-share';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function SuperInvestorStocks() {
    const [latestInsiderBuysForSuperInvestorStocks, setLatestInsiderBuysForSuperInvestorStocks] = useState({});

    async function fetchInsiderBuysForSuperInvestorStocks(){
          const response = await fetch(`${process.env.REACT_APP_SUPER_INVESTOR_INSIDER_BUYS_URL}`);
          setLatestInsiderBuysForSuperInvestorStocks(await response.json())
    }

    useEffect(() => {
        fetchInsiderBuysForSuperInvestorStocks();
    }, []);

    function extractCoupleBigStocksForShare(){
        var dates = Object.keys(latestInsiderBuysForSuperInvestorStocks);

        var tickers = "";
        latestInsiderBuysForSuperInvestorStocks[dates[0]].forEach(function(ticker){
            tickers += " $"+ticker;
        })

        return tickers;
    }

    function prepareInsiderBuys(){
        const insiderBuysFragment = [];
        for (const [date, tickers] of Object.entries(latestInsiderBuysForSuperInvestorStocks)) {
            const tickersViewComponent = [];
            tickers.forEach(function(ticker){
                tickersViewComponent.push(
                    <small key={ticker}>
                        <Badge bg="dark" style={{marginTop: "6px", marginLeft: "5px"}}>
                            <a href={"/?search="+ticker} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{ticker}</a>
                        </Badge>
                    </small>
                )
            })

            insiderBuysFragment.push(
            <Col className="text-center" key={date} style={{padding: "20px"}}>
                <Badge bg="secondary" text="dark"><h3>{date} {tickersViewComponent}</h3></Badge>
            </Col>);
        }

        return insiderBuysFragment
    }

    if(latestInsiderBuysForSuperInvestorStocks == null || Object.keys(latestInsiderBuysForSuperInvestorStocks).length === 0){
        return "";
    }

    return (
      <>
        <p>&nbsp;</p>
          <div align="left" style={{width: '60%', display: 'inline-block', paddingLeft: '35px'}}>
              <h3 className="text-muted"><Icon.Coin /> &nbsp; Latest insider buys of Super Investor holdings:</h3>
          </div>
          <div align="right" style={{width: '40%', display: 'inline-block', paddingRight: '35px'}}>
              Share on &nbsp;
              <TwitterShareButton url={'https://www.sigmatrader.net'} title={"Latest insider buys of Super Investor holdings ".concat(extractCoupleBigStocksForShare())} hashtags={["sigmatrader", "trading", "investing"]}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"/>
                  </svg>
              </TwitterShareButton>
          </div>
          <Container fluid="md">
              <Row>
                {prepareInsiderBuys()}
              </Row>
          </Container>
      </>
    );
}